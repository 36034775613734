// SIDEBAR
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

//The actual fuction
function toggleMenu() {
  var ele = document.getElementsByTagName('body')[0];
  if (!hasClass(ele, "menu-open")) {
    addClass(ele, "menu-open");
  } else {
    removeClass(ele, "menu-open");
  }
}

//Add event from js the keep the marup clean
function init() {
  document.getElementById("open-menu").addEventListener("click", toggleMenu);
  document.getElementById("body-overlay").addEventListener("click", toggleMenu);
  var elements = document.getElementsByClassName("sidebar-link-item");
  for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener("click", toggleMenu);
  }
}
//Prevent the function to run before the document is loaded
document.addEventListener('readystatechange', function () {
  if (document.readyState === "complete") {
    init();
  }
});

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});
// loading
$('.btn-load').on('click', function () {

  $.post('/cookie-loading', () => {
    $('html').removeClass('locked')
    $('body').removeClass('locked')
    $('#loading').fadeOut();
  });
});

AOS.init();

$(() => {
  $(window).on('scroll', async function () {

    $('.lazy-img').each(async function (key, item) {
      let lazyType = $(item).data('lazytype');
      let imgSource = $(item).data('src');

      if (lazyType == 'bg') {
        $(item).css('background-image', "url('" + imgSource) + "')";
      } else {
        $(item).attr('src', imgSource);
      }
      $(item).removeClass('lazy-img');
    })

  })
})
